import * as React from 'react'
import './src/styles/global.css'
import { LoginProvider } from './src/context/LoginContext';

export const wrapRootElement = ({ element }) => {
  return (
    <LoginProvider>
      {element}
    </LoginProvider>
  );
}

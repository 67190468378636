import React, { useState, useEffect, createContext } from 'react';

const TARGET_PARAMS = {
  CUSTOMER_TOKEN: 'customerToken',
  CUSTOMER_NO: 'customerNo',
  REDIRECT_URL: 'redirectUrl',
}

enum ERROR_MESSAGES {
  AUTO_AUTH_FAILED,
  AUTH_FAILED,
  FATAL_ERROR
}

const LoginContext = createContext({
  loading: false,
  error: false,
  showLogin: false,
  login: () => {}
});

const LoginProvider = ({children}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const redirectUrl = urlParams.get(TARGET_PARAMS.REDIRECT_URL) ?? "https://mhub.getlearnworlds.com/"
  const customerNumber = urlParams.get(TARGET_PARAMS.CUSTOMER_NO)
  const customerToken = urlParams.get(TARGET_PARAMS.CUSTOMER_TOKEN)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [showLogin, setShowLogin] = useState(!customerNumber || !customerToken)

  useEffect(() => {
    if (customerNumber && customerToken) {
      tokenAuth()
    } else {
      setShowLogin(true)
    }
  }, [])

  const login = async (email, password) => {
    setLoading(true)
    if (loading) return
    try {
      const response = await fetch(process.env.GATSBY_API_URL, {
        method: 'POST',
        body: JSON.stringify({
          authType: 'login',
          email: email,
          password: password,
          redirectUrl: redirectUrl
        })
      })
      const body = await response.json()

      if (!response.ok) {
        setLoading(false)
        setError(ERROR_MESSAGES.AUTH_FAILED)
        return
      }

      setError(false)
      window.location.replace(body.data.redirectUrl)
    } catch (error) {
      setError(ERROR_MESSAGES.FATAL_ERROR)
    }
  }

  const tokenAuth = async () => {
    setLoading(true)
    setShowLogin(false)
    try {
      const response = await fetch(process.env.GATSBY_API_URL, {
        method: 'POST',
        body: JSON.stringify({
          authType: 'token',
          customerNumber: customerNumber,
          customerToken: customerToken,
          redirectUrl: redirectUrl
        })
      })
      const body = await response.json()

      if (!response.ok) {
        setLoading(false)
        setShowLogin(true)
        setError(ERROR_MESSAGES.AUTO_AUTH_FAILED)
        return
      }

      setError(false)
      window.location.replace(body.data.redirectUrl)
    } catch (error) {
      setError(ERROR_MESSAGES.FATAL_ERROR)
    }
  }

  return (
    <LoginContext.Provider value={{
      loading,
      error,
      showLogin,
      login
    }}>
      {children}
    </LoginContext.Provider>
  );
};

export { LoginProvider, LoginContext, ERROR_MESSAGES };
